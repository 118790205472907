import sweaty from './images/sweaty-politics.jpg';
import './App.css';
import Footer from './Footer'
import ReactPlayer from 'react-player';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>The Adventure Begins Here</h1>
      </header>
      <ReactPlayer url='https://www.youtube.com/watch?v=8QEAA94FjHc'/>
      <p>Follow the hunt of the car of the man behind the wheel.</p>
      <Footer/>
    </div>
  );
}

function Campaign() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Matt McConaughtey for Texas Governor™™™™</h1>
      </header>
      <img 
        className="image"
        src={sweaty} 
        alt="sweaty politics boy" />
      <br/>

      <p>
        "Would be a step up. Maybe not the ideal candidate for the job, but right now the candidate for the job who has the job, is a real stinker."
      </p>
      
      <p>
        "Basically already the governor of Huntington WV, because he made a movie about Marshall there like 12 years ago."
      </p>
      <br/>

      <Footer/>
    </div>
  );
}

export {Home, Campaign};
