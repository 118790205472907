import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
      <div>
          <footer>
            <Link 
            className="footer-link"
            to='/'>
                Home
            </Link>
            |
            <Link 
            className="footer-link"
            to='/mattmcconaughteyforgovvvv'>
                Campaign
            </Link>
            |
            <a
            className="footer-link"
            href="https://www.themcelroy.family/2021/7/12/22573896/mbmbam-568-will-a-sign-help"
            target="_blank"
            rel="noopener noreferrer"
            >
                Podcast
            </a>
            |
            <a
            className="footer-link"
            href="https://www.gofundme.com/c/act/stop-aapi-hate"
            target="_blank"
            rel="noopener noreferrer"
            >
                Donate
            </a>
          </footer>
      </div>
    );
}

export default Footer;